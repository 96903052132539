<template>
  <v-col cols="12" class="pb-0 d-inline-flex justify-center align-center flex-column">
    <v-slide-y-transition mode="out-in" appear>
      <v-btn color="body" elevation="0" class="d-block" :ripple="false" icon @click="$emit('toSubasta')" style="margin-left: 5px;"><img src="assets/ic-arrow-celest-bottom.svg" /></v-btn>
    </v-slide-y-transition>
    <v-slide-y-transition mode="out-in" appear>
      <v-img eager class="d-block separator-size" :src="separator" />
    </v-slide-y-transition>
  </v-col>
</template>

<script>
export default {
  name: 'ArrowSeparator',
  props: {
    separator: {
      type: String,
      default: 'assets/separator-blue-bottom.svg'
    }
  }
}
</script>

<style>

</style>